import * as React from "react"
import { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel from 'nuka-carousel';

import Link from 'components/link';
import PostCard from 'components/postCard';

import * as style from 'styles/articles.module.scss';

const Articles = (props) => {
	const getWidth = () => (typeof window !== 'undefined' ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth : 0);

	let [width, setWidth] = useState(getWidth());

	const handleResize = () => setWidth(getWidth());

	const getSlides = (width) => {
		if (width <= 640)return 1;
		if (width > 640 && width <= 1000)return 2;
		return 3;
	}
	
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	});

	const { allBlogPost: data } = useStaticQuery(
		graphql`
			query {
			  allBlogPost(
			    limit: 3
			    sort: {fields: showDate, order: DESC}
			  ) {
			    edges {
			      node {
			      	id
			        title
			        intro
			        permalink
			        photo
			      }
			    }
			    pageInfo {
			      hasNextPage
			      perPage
			      currentPage
			      totalCount
			    }
			  }
			}
		`
	);
	const settings = {
		autoplay: false,
		wrapAround: false,
		autoGenerateStyleTag: false,
		renderTopLeftControls: null,
		renderTopCenterControls: null,
		renderTopRightControls: null,
		renderCenterLeftControls: null,
		renderCenterCenterControls: null,
		renderCenterRightControls: null,
		renderBottomLeftControls: null,
		renderBottomCenterControls: null,
		renderBottomRightControls: null,
		slidesToShow: getSlides(width),
		slideWidth: (getSlides(width) === 1 ? .9 : 1),
		cellSpacing: 20,
	}

	const { edges: nodes } = data;
	const posts = nodes.map(({node}) => node);
	const { pageInfo } = data;
	if (posts.length === 0)return null;

	return (
		<section className={style.articles}>
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="cell small-12 medium-9">
						<div className={style.headline}>Explore Inclusion & Diversity Topics</div>
					</div>
				</div>
			</div>

			{ !!props.slider === false &&
				<div className="grid-container">
					<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
						{posts.map(post => <div className={`cell ${style.card}`}><PostCard {...post} /></div>)}
					</div>
				</div>
			}

			{ !!props.slider !== false &&
				<div className={`grid-container ${style.noRightContainer}`}>
					<div className={`grid-x grid-margin-x ${style.noRightGrid}`}>
						<div className={`cell small-12 ${style.noRightCell}`}>
							<Carousel {...settings}>
								{posts.map(post => <div className={style.card}><PostCard {...post} /></div>)}
							</Carousel>
						</div>
					</div>
				</div>
			}

			{ pageInfo.hasNextPage &&
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<div className={style.pager}>
								<Link to="/articles/" className="button secondary dark">See More Articles</Link>
							</div>
						</div>
					</div>
				</div>
			}

		</section>
	)
}

export default Articles