import * as React from "react"

import Card from './card';
import * as style from 'styles/focusAreas.module.scss';

const Focus = (props) => {
	return (
		<section className={style.container}>
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="small-12 medium-offset-1 medium-10 end cell">
						<div className={style.introText}>
							<div className={style.headline}>I&D Strategic Plan Focus Areas</div>
							<p>To get the results we want, we need to know where we are heading. That's why we spent 18 months talking with employees across the company to determine the best path. The result is a five-year I&D strategy that will guide our approach to creating an inclusive culture where everyone feels like they belong.</p>
							<p>There are four focus areas in our I&D Strategic Plan:</p>
						</div>
					</div>
				</div>
			</div>
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="small-12 cell">
						<Card
							leftTitle="Learning & Awareness"
							leftDescription="We can all benefit from the experiences and perspectives each of us brings to work. There is value in learning how to share and discuss those experiences thoughtfully and constructively."
							icon="openBook"
							rightTitle="We Are"
							points={[
								"Developing training to increase awareness and understanding of behaviors and attitudes that can impact others.",
								"Providing tools and tips for leaders that encourage real and honest conversations with their teams.",
								"Creating awareness around what inclusive language means and how it affects others.",
								"Helping employees find their personal connection to making everyone feel valued and respected.",
							]}
						/>

						<Card
							leftTitle="Access & Opportunity"
							leftDescription="We are creating the opportunity for employees to perform their best work and be treated fairly by removing barriers when we can. Taking a closer look at our policies and processes helps us make sure that's possible for everyone."
							icon="people"
							rightTitle="Our Focus Is On"
							points={[
								"Evaluating HR processes, policies and practices and removing barriers to identify ways we can provide opportunities for all.",
								"Intentional succession planning and career development at all levels.",
								"Reviewing recruitment and promotion practices.",
								"Engaging ERGs and capturing their perspectives throughout our initiatives.",
							]}
						/>

						<Card
							leftTitle="Transparency & Accountability"
							leftDescription="To achieve our goal of building a high-performing workforce, we need tools to measure where we are and how we'll keep making progress toward our goal. We all know that what gets measured gets done."
							icon="check"
							rightTitle="We Will"
							points={[
								"Use data to identify gaps and opportunities to build a high-performing, diverse workforce.",
								"Look for trends in existing data and information.",
								"Be transparent and communicate progress over time.",
							]}
						/>

						<Card
							leftTitle="Communication & Resources"
							leftDescription={`We will provide updates on what we are doing and the progress we're making toward our goals – providing the "why" and "how" with clear messaging. We will give local leaders tools to reinforce consistent messaging across the company – which we heard from employees is very important to ensure the experience matches the message.`}
							icon="chat"
							rightTitle="This Includes"
							points={[
								`Explaining the "why" and how it relates to our overall business strategy.`,
								"Delivering consistent, frequent information company-wide.",
								"Creating tools and empowering conversations at the local level.",
								"Developing a feedback loop to monitor our employees' experience.",
							]}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Focus