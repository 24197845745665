// extracted by mini-css-extract-plugin
export var animatedRight = "focusCard-module--animatedRight--FuetS";
export var arrow = "focusCard-module--arrow--yzSmm";
export var container = "focusCard-module--container--yjTeh";
export var icon = "focusCard-module--icon--sVgON";
export var internal = "focusCard-module--internal--VaRzD";
export var left = "focusCard-module--left--xo4b3";
export var leftDescription = "focusCard-module--leftDescription--yz4Vd";
export var leftTitle = "focusCard-module--leftTitle--6fPmf";
export var point = "focusCard-module--point--GBkX3";
export var points = "focusCard-module--points--jtYR4";
export var right = "focusCard-module--right--CSgXh";
export var rightTitle = "focusCard-module--rightTitle--8Tc7q";
export var show = "focusCard-module--show--gdjOI";