import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import Link from 'components/link';

import * as style from 'styles/events.module.scss';

const Events = (props) => {
	const { allEventsEvent: data } = useStaticQuery(
		graphql`
			query {
			  allEventsEvent {
			    edges {
			      node {
			      	id
			        title
			        intro
			        permalink
			        photo
			        link
			      }
			    }
			  }
			}
		`
	);
	const { edges: nodes } = data;
	const posts = nodes.map(({node}) => node);
	if (posts.length === 0)return null;
	return (
		<section className={style.events}>
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="medium-6 small-12 cell">
						<div className={style.title}>Inclusion & Diversity at ONE Gas</div>
						<p className={style.large}>Every employee brings unique perspectives and experiences to their role at ONE Gas. We are creating an environment that invites and respects those differences as we learn new skills, find solutions and make ONE Gas a great place to work.</p>
						<p className={style.large}>One of our strategic goals is to support our high-performing workforce. An inclusive environment leverages our diversities, drives innovation and increases employee engagement achieve this high-performing state.</p>
					</div>
					<div className="medium-6 small-12 cell">
						{posts.map(post => (
							<Link key={post.id} href={(!!post.link !== false ? post.link : `/event/${post.permalink}/`)} className={style.upcomingEvent}>
								{ !!post.photo && <div className={style.photo} style={{backgroundImage: `url(${process.env.IMGIX_DOMAIN}${post.photo}?max-w=960&fm=auto)`}}></div> }
								<div>
									<div className={style.title}>{post.title}</div>
									<div className={style.intro}>{post.intro}</div>
									<div className={style.learnMore}>Learn More</div>
								</div>
							</Link>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Events