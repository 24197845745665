import * as React from "react"
import { graphql } from 'gatsby';

import Articles from 'components/articles';
import Events from 'components/events';
import Focus from 'components/focus';
import Layout from 'components/layout';
import Mission from 'components/mission';
import Seo from 'components/seo';
// import Strategies from 'components/strategies';

const IndexPage = (props) => {
	let page = null;
	if (props.data.allContentPage.edges.length)page = props.data.allContentPage.edges[0].node;
	return (
		<Layout masthead={true}>
			<Seo title={page.pageTitle} description={page.pageDescription} meta={page.meta} />
			<Events />
			<Articles slider={true} />
			{/*<Strategies />*/}
			<Focus />
			<Mission />
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query {
	  allContentPage(filter: {uri: {eq: "/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
			uri
	        content {
	          main
	        }
	        meta
			pageTitle
			pageDescription
	      }
	    }
	  }
	}
`