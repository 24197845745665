import * as React from "react"
import { useState, useEffect } from "react"
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';

import Link from 'components/link';

import arrow from 'images/arrow-right-solid.svg';
import * as style from 'styles/focusCard.module.scss';

const Card = (props) => {
	const getWidth = () => (typeof window !== 'undefined' ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth : 0);
	const defaultHeight = 0;
	const [show, setShow] = useState((getWidth() >= 768 || typeof window === 'undefined'));
	const [contentHeight, setContentHeight] = useState(defaultHeight);
	let [width, setWidth] = useState(getWidth());

	const [ref, { height }] = useMeasure();

	const springProps = useSpring({
		config: {
			duration: 300,
		},
		from: {
			opacity: 0,
			height: defaultHeight,
		},
		opacity: (show ? 1 : 0),
		height: (show ? contentHeight : defaultHeight),
	});


	useEffect(() => {
		function handleResize() {
			setContentHeight(height);
			setWidth(getWidth());
			setShow((getWidth() >= 768));
		}
		handleResize();
		window.addEventListener('resize', handleResize);
		return window.removeEventListener('resize', handleResize);
	}, [height]);

	let { points } = props;
	if (!Array.isArray(points))points = [];

	const handleToggle = (evt) => {
		if (width >= 768) {
			evt.preventDefault();
			evt.stopPropagation();
			return false;
		}
		setShow(!show);
	}

	return (
		<div className={style.container}>
			<div className={style.left}>
				<img className={style.icon} src={`/images/icons/${props.icon}.png`} alt="" />
				<div className={style.leftTitle}>{props.leftTitle}</div>
				<div className={style.leftDescription}>{props.leftDescription}</div>
				<div role="presentation" onClick={handleToggle} className={`${style.arrow} ${(show ? style.show : '')}`}><img src={arrow} alt="" /></div>
				{ !!props.link && <Link to={props.link} className="show-for-medium button secondary dark">{props.linkText||'Learn More'}</Link> }
			</div>
			<div className={`show-for-medium ${style.right}`}>
				<div className={style.rightTitle}>{props.rightTitle}</div>
				<ul className={style.points}>
					{points.map( (point, index) => (
						<li key={index} className={style.point}>{point}</li>
					))}
				</ul>
				{ !!props.link && <Link to={props.link} className="hide-for-medium button secondary dark">{props.linkText||'Learn More'}</Link> }
			</div>
			<animated.div className={`hide-for-medium ${style.animatedRight}`} style={springProps}>
				<div ref={ref} className={style.right}>
					<div className={style.rightTitle}>{props.rightTitle}</div>
					<ul className={style.points}>
						{points.map( (point, index) => (
							<li key={index} className={style.point}>{point}</li>
						))}
					</ul>
					{ !!props.link && <Link to={props.link} className="hide-for-medium button secondary dark">{props.linkText||'Learn More'}</Link> }
				</div>
			</animated.div>
		</div>
	)
}

export default Card