import * as React from "react"

import Link from 'components/link';
import * as style from 'styles/postCard.module.scss';

const PostCard = ({className, permalink, photo, title, intro}) => {
	const classes = [style.card];
	if (!!className !== false)classes.push(className);

	return (
		<Link className={style.card} to={`/post/${permalink}/`}>
			{ !!photo && <div className={style.photo} style={{backgroundImage: `url(${process.env.IMGIX_DOMAIN}${photo})`}}></div> }
			<div className={style.body}>
				<div className={style.title}>{title}</div>
				<div className={style.intro}>{intro}</div>
			</div>
			<div className={style.plus}/>
		</Link>
	)
}

export default PostCard